<template>
	<div class="uu-shop">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane :label="$t('seller.shop.infoPane1')" name="base">
				<el-scrollbar class="uu-box">
					<div class="openshop-container">
						<div class="openshop-adapt">
								<div class="openshop-adapt-main">
									<el-form ref="stoerForm" :model="stoerForm" :rules="stoerRules" label-position="top">
										<el-form-item>
											<div class="message-notice">
												<i class="el-icon-warning"></i>
												<div class="message-notice-main">{{$t('seller.shop.infoTips1-1')}}{{stoerForm.modify_store_name_num}}{{$t('seller.shop.infoTips1-2')}}</div>
											</div>
										</el-form-item>
										<el-form-item :label="$t('seller.formItem.shopName2')" prop="store_name">
											<el-input v-model="stoerForm.store_name" maxlength="20" show-word-limit autocomplete="off" :placeholder="$t('seller.placeholder.shopName2')"></el-input>
										</el-form-item>
										<el-form-item :label="$t('seller.formItem.shopId')">
											<el-input v-model="stoerForm.store_id" :disabled="true" autocomplete="off"></el-input>
										</el-form-item>
										<el-form-item :label="$t('seller.formItem.shopLogo')">
											<template v-if="stoerForm.store_logo">
												<div class="item-thumb">
													<img :src="stoerForm.store_logo" @error="imageLoadError">
													<div class="item-thumb-del" @click="handleThumbDel"><i class="el-icon-delete"></i></div>
												</div>
											</template>
											<template v-else>
												<el-upload
													action="#"
													:show-file-list="false"
													v-loading="loadingUplaod"
													:http-request="httpLogoRequest">
													<div class="logo-plus">
														<i class="el-icon-plus"></i>
														<p>{{$t('seller.upload.image')}}</p>
													</div>
												</el-upload>
											</template>
										</el-form-item>
										<el-form-item :label="$t('seller.formItem.addresses')" prop="address">
											<el-row :gutter="10">
												<el-col :span="8" v-for="(item,index) in regionList" :key="index">
													<el-select v-model="regionIds[index]" clearable @change="handleRegionChange($event,index)">
														<el-option
															v-for="option in item"
															:key="option.region_id"
															:label="option.region_name"
															:value="option.region_id">
														</el-option>
													</el-select>
												</el-col>
											</el-row>
											<el-input v-model="stoerForm.address" maxlength="60" class="mt12" autocomplete="off" :placeholder="$t('seller.placeholder.default')"></el-input>
											<div class="notice">{{$t('seller.shop.infoTips2')}}</div>
										</el-form-item>
										<el-form-item>
											<div class="openshop-adapt-foot">
												<el-button type="primary" class="btn-save" :disabled="isSubmit" @click="handleSubmit" round>{{$t('seller.actions.save')}}</el-button>
											</div>
										</el-form-item>
									</el-form>
								</div>
						</div>
						<div class="openshop-fix">
							<div class="openshop-fix-title">{{$t('seller.shop.infoBar1')}}</div>
							<div class="openshop-fix-block">
								<div class="block-title">{{$t('seller.shop.infoBar1-1')}}</div>
								<div class="block-row">
									<div class="openshop-fix-card">
										<img v-if="stoerForm.store_logo" :src="stoerForm.store_logo" class="card-img" @error="imageLoadError" alt="">
										<div v-else class="card-img"></div>
										<div class="card-info">
											<div class="card-info-name">{{stoerForm.store_name}}</div>
											<div class="card-info-desc">{{$t('seller.shop.infoBar1-2')}}{{$store.state.user.userName}}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="openshop-fix-block">
								<div class="block-title">{{$t('seller.shop.infoBar2')}}</div>
								<div class="block-row">
									<div class="openshop-mobilefix-card">
										<div class="card-head">
											<img v-if="stoerForm.store_logo" :src="stoerForm.store_logo" class="card-head-pic" @error="imageLoadError" alt="">
											<div class="card-head-pic" v-else></div>
											<div class="card-head-info">
												<div class="card-info-name">{{stoerForm.store_name}}</div>
												<div class="card-info-desc">{{$t('seller.shop.infoBar2-1')}} xx%</div>
											</div>
										</div>
										<div class="card-main">
											<div class="card-img">{{$t('seller.shop.infoBar2-2')}}</div>
											<div class="card-img">{{$t('seller.shop.infoBar2-2')}}</div>
											<div class="card-img">{{$t('seller.shop.infoBar2-2')}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-scrollbar>
			</el-tab-pane>
			<!-- <el-tab-pane :label="$t('seller.shop.infoPane2')" name="domain">
				<el-scrollbar>
					<div class="openshop-container">
						<div class="openshop-adapt">
							<div class="openshop-adapt-main">
								<el-form ref="domainForm" :model="domainForm" :rules="domainRules" label-position="top">
									<template v-if="stoerForm.open_shop!==2">
										<el-form-item>
											<div class="message-notice">
												<i class="el-icon-warning"></i>
												<div class="message-notice-main">{{$t('seller.shop.domainTips1')}}</div>
											</div>
										</el-form-item>
									</template>
									<el-form-item :label="$t('seller.formItem.shopDomain')" prop="domain">
										<el-input v-model="domainForm.domain" maxlength="32" show-word-limit autocomplete="off" :disabled="domainDisabled" :placeholder="$t('seller.placeholder.default')">
											<template slot="prefix">{{localhostText}}</template>
										</el-input>
										<el-checkbox v-model="domainForm.isAgree">{{$t('seller.checkbox.openAgreement1')}}</el-checkbox><a href="#" class="domain-rule">{{$t('seller.checkbox.openAgreement1')}}</a>
									</el-form-item>
									<el-form-item>
										<div class="openshop-adapt-foot">
											<el-button type="primary" class="btn-save" :disabled="isSubmit" @click="handleSubmitDomain" round>{{$t('seller.actions.save')}}</el-button>
										</div>
									</el-form-item>
								</el-form>
							</div>
						</div>
						<div class="openshop-fix">
							<div class="openshop-fix-title">{{$t('seller.shop.domainBar1')}}</div>
							<div class="openshop-fix-block">
								<div class="block-title">{{$t('seller.shop.domainBar1-1')}}</div>
								<div class="block-row">
									<div class="openshop-domaincard">
										<div class="card-tab">
											<div class="card-tab-main">
												<img src="" class="card-tab-icon" alt="">
												<div class="card-tab-name">{{$t('seller.shop.domainBar1-2')}}-{{stoerForm.store_name}}-{{$t('seller.shop.domainBar1-3')}}</div>
											</div>
										</div>
										<div class="card-main">
											<div class="card-text">{{localhostText}}{{domainForm.domain}}</div>
										</div>
										<div class="card-empty"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-scrollbar>
			</el-tab-pane> -->
		</el-tabs>
	</div>
</template>

<script>
	import { fetchRegion } from '@/api/user';
	import { getInfo } from '@/api/seller/index';
	import { updateShop, setDomain } from '@/api/seller/shop';
	import { normalOSSUpload } from '@/utils/upload';
	export default {
		data() {
			var validateAddress = (rule, value, callback) =>{
				if(this.stoerForm.address === ''){
					callback(new Error(this.$t('seller.validator.address')));
				}else if(!this.regionIds.length>0){
					callback(new Error(this.$t('seller.validator.region')));
				}else {
					callback();
				}
			}
			return {
				activeName: 'base',
				stoerForm: {
					store_id: 0,
					store_name: '',
					store_logo: '',
					region_id: '',
					region_id1: '',
					region_id2: '',
					region_id3: '',
					address: '',
					region_name: '',
					open_shop: 0,
					modify_store_name_num: 3,
				},
				stoerRules: {
					store_name: [
						{ required: true, message: this.$t('seller.validator.shopName'), trigger: 'blur' }
					],
					address: [
						{ required: true, validator: validateAddress, trigger: 'blur' }
					]
				},
				domainForm: {
					domain: '',
					isAgree: false,
				},
				domainRules: {
					domain: [
						{ required: true, message: this.$t('seller.validator.shopDomain1'), trigger: 'blur' },
						{ pattern: /^[0-9a-zA-Z-]+([0-9a-zA-Z]{1})$/, message: this.$t('seller.validator.shopDomain2'), trigger: 'blur' }
					]
				},
				regionList: [],
				regionIds: [],
				isSubmit: true,
				localhostText: this.$testBaseURL+'/',
				loadingUplaod: false,
				domainDisabled: true,
			}
		},
		created() {
			this.activeName = this.$route.query.type?this.$route.query.type:this.activeName
			this.getStoreInfo();
		},
		methods: {
			imageLoadError(e){
				e.target.src = this.$imageErrorGoods;
				e.target.style.objectFit = 'fill';
			},
			getStoreInfo(){
				let loading = this.$loading();
				getInfo({uuid: this.$store.getters.uuid}).then(response => {
					const { data } = response;
					this.stoerForm = data
					let promiseArr = [this.initRegionList()];
					let regionList = [];
					let regionIds = [];
					for(let i=1;i<4;i++){
						if(data['region_id' + i] > 0){
							regionIds.push(data['region_id' + i]);
							promiseArr.push(this.initRegionList(data['region_id' + i]));
						}
					}
					Promise.all(promiseArr).then(object=>{
						object.forEach(item=>{
							if(item.length>0){
								regionList.push(item);
							}
						})
					})
					this.regionList = regionList;
					this.regionIds = regionIds;
					if(data.open_shop==2){
						this.domainDisabled = false;
					}else {
						this.domainDisabled = true;
					}
					if(data.domain){
						this.domainForm.domain = data.domain;
						this.domainForm.domain = this.domainForm.domain.replace(this.localhostText,'');
					}
					this.$nextTick(()=>{
						this.isSubmit = true
					})
					loading.close()
				}).catch(()=>{
					loading.close()
				})
			},
			initRegionList(pid=45056){
				return new Promise((resolve,reject)=>{
					fetchRegion({parent_id: pid}).then(response => {
						resolve(response.data.list)
					}).catch(error => {
						reject(error)
					})
				})
			},
			getRegionList(pid=45056,index=-1){
				fetchRegion({parent_id: pid}).then(response => {
					this.regionList.splice(index);
					if (response.data.list && response.data.list.length > 0) {
						this.regionList.push(response.data.list);
					}
				})
			},
			handleClick(tab, event){
				this.$router.push({query:{
					type: tab.name
				}})
				this.isSubmit = true;
			},
			async httpLogoRequest(value){
				const { file } = value;
				const istype = ['image/jpeg','image/png'].includes(file.type);
				const isSize = file.size / 1024 / 1024 < 3;
				if (!istype) {
					this.$message.error(this.$t('seller.upload.imageType'));
					return false;
				}
				if (!isSize) {
					this.$message.error(this.$t('seller.upload.imageSize3M'));
					return false;
				}
				this.loadingUplaod = true;
				const { code, data } = await normalOSSUpload(file,'other');
				if(code==200){
					this.stoerForm.store_logo = data.url;
				}else{
					this.$message.error(this.$t('seller.errorMsg.upload'))
				}
				this.loadingUplaod = false;
			},
			handleThumbDel(){
				this.stoerForm.store_logo = '';
			},
			handleRegionChange(val, index){
				if(val){
					this.regionIds.splice(index + 1);
					this.getRegionList(val, index + 1);
				}else {
					this.regionIds.splice(index);
					this.regionList.splice(index+1);
				}
			},
			handleSubmit(){
				let that = this;
				let loading = that.$loading();
				if(that.regionIds){
					that.stoerForm.region_id = that.regionIds[that.regionIds.length-1];
					that.stoerForm.region_id1 = that.regionIds[0] > 0 ? that.regionIds[0] : 0;
					that.stoerForm.region_id2 = that.regionIds[1] > 0 ? that.regionIds[1] : 0;
					that.stoerForm.region_id3 = that.regionIds[2] > 0 ? that.regionIds[2] : 0;
					let regionName = '';
					for(let i = 0;i<that.regionIds.length;i++){
						that.regionList[i].forEach(item=>{
							if(item.region_id == that.regionIds[i]){
								regionName+=item.region_name;
							}
						})
					}
					that.stoerForm.region_name = regionName;
				}
				that.$refs.stoerForm.validate((valid) => {
					if(valid){
						if(!that.stoerForm.store_logo){
							loading.close();
							that.$message.error(that.$t('seller.errorMsg.shopLogo'));
							return false;
						}
						let param = {
							address: that.stoerForm.address,
							region_id: that.stoerForm.region_id,
							region_name: that.stoerForm.region_name,
							store_name: that.stoerForm.store_name,
							store_logo: that.stoerForm.store_logo
						}
						updateShop(param).then(() => {
							loading.close();
							that.isSubmit = true;
							that.$store.dispatch('user/getInfo');
							that.$message.success(that.$t('seller.successMsg.save'));
							that.getStoreInfo();
						}).catch(()=>{
							loading.close();
						})
					}else{
						loading.close();
						return false
					}
				})
			},
			handleSubmitDomain(){
				let that = this;
				let loading = that.$loading();
				that.$refs.domainForm.validate((valid) => {
					if(valid){
						if(!that.domainForm.isAgree){
							loading.close();
							that.$message.error(that.$t('seller.errorMsg.agreement'));
							return false;
						}
						let domain = that.localhostText+that.domainForm.domain;
						setDomain({domain: domain}).then(() => {
							loading.close();
							that.$message.success(that.$t('seller.successMsg.save'));
						}).catch(() => {
							loading.close();
						})
					}else{
						loading.close();
						return false
					}
				})
			}
		},
		watch: {
			'stoerForm': {
				handler: function(newValue, oldValue) {
					this.isSubmit = false;
				},
				deep: true,
				immediate: true
			},
			'regionIds': {
				handler: function(newValue, oldValue) {
					this.isSubmit = false;
				},
				immediate: true
			},
			'$store.getters.userInfo': {
				handler: function(newValue, oldValue) {
					if(newValue && newValue.school_info){
						this.localhostText = newValue.school_info.domain_prefix+'.'+this.$testBaseURL+'/';
						if(this.domainForm.domain){
							this.domainForm.domain = this.domainForm.domain.replace(this.localhostText,'');
						}
					}
				},
				deep: true,
				immediate: true
			},
			'domainForm': {
				handler: function(newValue, oldValue) {
					this.isSubmit = false;
				},
				deep: true,
				immediate: true
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-shop {
		flex: 1;
		width: 0;
		::v-deep .el-tabs {
			height: 100%;
			.el-tabs__header {
				margin-bottom: 0;
				.el-tabs__nav-wrap {
					padding: 0 24px;
				}
				.el-tabs__nav-wrap::after {
					height: 1px;
				}
			}
			.el-tabs__content {
				height: calc(100% - 40px);
				.el-tab-pane {
					height: 100%;
				}
			}
		}
		.uu-box {
			height: calc(100vh - 115px);
		}
		.openshop-container {
			display: flex;
			padding: 42px 0;
			.openshop-adapt {
				flex: 1;
				.openshop-adapt-main {
					max-width: 600px;
					margin: 0 auto;
					padding: 0 20px;
					.message-notice {
						padding: 12px;
						border-radius: 9px;
						line-height: 18px;
						background-color: $--seller-notice-background;
						.el-icon-warning {
							color: $--seller-primary;
							float: left;
							font-size: 18px;
						}
						.message-notice-main {
							width: 100%;
							color: #666666;
							font-size: 12px;
							padding-left: 24px;
							padding-right: 15px;
							box-sizing: border-box;
							a {
								color: $--seller-primary;
							}
						}
					}
					.mt12 {
						margin-top: 12px;
					}
					.notice {
						color: #999999;
						line-height: 18px;
						margin-top: 3px;
					}
					.item-thumb {
						width: 72px;
						height: 72px;
						position: relative;
						border: 1px solid #c4c6cf;
						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
						.item-thumb-del {
							left: 0;
							bottom: 0;
							width: 100%;
							height: 20px;
							line-height: 20px;
							text-align: center;
							font-size: 16px;
							color: #fff;
							display: none;
							cursor: pointer;
							background-color: rgba(0,0,0,.7);
							position: absolute;
						}
					}
					.item-thumb:hover {
						.item-thumb-del {
							display: block;
						}
					}
					.logo-plus {
						color: #999999;
						width: 72px;
						height: 72px;
						line-height: normal;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						border: 1px dashed #c4c6cf;
						border-radius: 12px;
						background-image: url('~@/assets/images/seller/default-store-75-75.png');
						background-repeat: no-repeat;
						background-size: 100% auto;
						flex-direction: column;
						p {
							font-size: 12px;
							line-height: 15px;
							margin-top: 9px;
						}
					}
					.logo-plus:hover {
						color: $--seller-primary;
						border-color: $--seller-primary;
					}
					.domain-rule {
						margin-left: 10px;
						color: $--seller-primary;
					}
					::v-deep .el-input--prefix {
						.el-input__inner {
							padding-left: 190px;
						}
						.el-input__prefix {
							width: 180px;
							overflow: hidden;
							white-space: normal;
							text-overflow: ellipsis;
						}
					}
					.openshop-adapt-foot {
						padding-top: 60px;
						text-align: center;
					}
				}
			}
			.openshop-fix {
				width: 411px;
				padding-left: 36px;
				padding-right: 50px;
				box-sizing: border-box;
				position: relative;
				min-height: 337px;
				.openshop-fix-title {
					text-align: center;
					font-size: 22px;
					color: #111111;
					line-height: 24px;
					margin-bottom: 30px;
				}
				.openshop-fix-block {
					margin-bottom: 36px;
					.block-title {
						color: #999;
						line-height: 18px;
						font-size: 12px;
						margin-bottom: 22px;
					}
					
					.openshop-fix-card {
						padding: 16px;
						width: 100%;
						display: inline-flex;
						box-shadow: 0 0 24px 0 rgba(0,0,0,.12);
						background-color: #FFFFFF;
						border-radius: 10px;
						box-sizing: border-box;
						.card-img {
							width: 72px;
							height: 72px;
							border-radius: 6px;
							background-color: #f0f2f5;
						}
						.card-info {
							display: flex;
							flex-flow: column;
							margin-left: 14px;
						}
						.card-info-name,.card-info-desc {
							font-size: 14px;
							line-height: 14px;
							max-width: 166px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							padding-bottom: 4px;
							margin-bottom: 8px;
						}
						.card-info-name {
							color: $--seller-primary;
						}
					}
					.openshop-mobilefix-card {
						padding: 16px;
						width: 100%;
						display: inline-block;
						box-shadow: 0 0 24px 0 rgba(0,0,0,.12);
						background-color: #FFFFFF;
						border-radius: 10px;
						box-sizing: border-box;
						.card-head {
							display: flex;
							margin-bottom: 10px;
							.card-head-pic {
								width: 33px;
								height: 33px;
								object-fit: contain;
								border-radius: 50%;
							}
							div.card-head-pic {
								background-color: $--seller-thead-background-2;
							}
							.card-head-info {
								margin-left: 10px;
								height: 33px;
								.card-info-name,.card-info-desc {
									font-size: 12px;
									line-height: 12px;
									max-width: 178px;
									overflow: hidden;
									white-space: nowrap;
									color: #999999;
									text-overflow: ellipsis;
									padding-bottom: 4px;
								}
								.card-info-name {
									color: #333333;
									margin-bottom: 4px;
								}
							}
						}
						.card-main {
							display: flex;
							.card-img {
								width: 93px;
								height: 91px;
								line-height: 91px;
								text-align: center;
								font-size: 12px;
								color: rgb(204,204,204);
								border-radius: 6px;
								background-color: rgb(240,242,245);
								margin-right: 6px;
							}
							.card-img:last-child {
								margin-right: 0;
							}
						}
					}
					.openshop-domaincard {
						width: 100%;
						box-shadow: 0 0 24px 0 rgba(0,0,0,.12);
						background-color: #fbfbfb;
						border-radius: 8px;
						.card-tab {
							padding-top: 12px;
							border-radius: 8px 8px 0 0;
							background-color: #dee1e6;
							.card-tab-main {
								width: 218px;
								background-color: #FFFFFF;
								border-radius: 4px 4px 0 0;
								display: flex;
								margin-left: 10px;
								padding: 12px 16px;
								align-items: center;
							}
						}
						.card-main {
							padding: 12px 0 12px 12px;
							background-color: #FFFFFF;
							border-bottom: 1px solid rgba(0,0,0,.1);
						}
						.card-text {
							background-color: #f1f3f4;
							border-radius: 19px 0 0 19px;
							padding: 12px 0 12px 18px;
							font-size: 14px;
							line-height: 1;
							color: #333;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
						.card-empty {
							height: 76px;
							background-color: #fbfbfb;
						}
					}
				}
			}
			.openshop-fix:before {
				content: "";
				top: 0;
				left: 0;
				height: 337px;
				display: block;
				position: absolute;
				border-left: 1px solid #eee;
			}
		}
	}
</style>
